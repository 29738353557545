.memorize {
  margin-bottom: 30px;
  border: 2px solid $ci-primary;
  padding: 10px 20px 10px 18px;
  display: inline-block;
  color: $ci-primary;
  cursor: pointer;
  span {
    //@extend .glyphicon;
    //@extend .glyphicon-star-empty;
    top: 2px;
    margin-right: 8px;
  }
  &.added-fav {
    background: $ci-primary;
    color: #fff;
    span {
      //@extend .glyphicon-star;
    }
  }
}

.object-gallery {
  padding-bottom: 70px;
}

.object-detail .object-infos {
  @include make-row(30px);
  padding-bottom: 80px;

  .object-details {
    @include make-col-ready(30px);
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }
  }
  .object-fact {
    @include make-col-ready(30px);
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }
  }

  h3 {
    padding-bottom: 30px;
  }
}

.object-infos {
  .object-data, .object-energy {
    ul {
      @include reset_list;
      padding: 0 0 60px;
      line-height: 30px;

      li {
        display: flex;
        flex-direction: row;
        div{
          flex-grow: 0;
        }
      }

      .label {
        width: 160px;
        align-self: flex-start;
      }
      .fact {
        margin: 0;
        width: 180px;
        padding: 0 0 0 15px;
        align-self: flex-end;
      }
    }
  }
  .object-energy {
    ul {
      padding: 0 0 30px;
    }
  }

  .object-features {
    ul {
      margin: 0;
      padding: 0 0 60px 20px;
      line-height: 30px;
    }
  }

  .object-client-box {
    text-align: center;
    border-top: 7px solid $ci-primary;
    background: $ci-grey-light;
    padding: 40px 20px;
    margin-bottom: 100px;

    .object-logo {
      margin-bottom: 50px;
      max-height: 100px;
      max-width: 200px;
    }

    .object-mandant-titel {
      margin-bottom: 50px;
    }

    p:last-child {
      padding-bottom: 0;
    }
  }
}

.object-client-contact {
  .object-logo {
    max-height: 100px;
    max-width: 200px;
  }

  @include media-breakpoint-down(sm) {
    .object-client-address {
      margin-top: 1.5em;
    }
  }
}
