.swiper-pagination {
  padding: 30px 0 0;
  bottom: auto;
  left: auto;

  .swiper-pagination-bullet {
    background: #cacaca;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: $ci-primary;
    opacity: 1;
  }
}

.swiper-container {
  @include clearfix;
}

.swiper-button-prev {
  left: 0px;

}
.swiper-button-next {
  right: 0px;
}
/*
.gallery-top {
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
}
*/
.gallery-top,
.gallery-top-zoom {
  width: 100%;
  height: 100%;

  .swiper-slide {
    overflow: hidden;
  }
}

.swiper-container{
  .swiper-slide{
    img{
      max-width:100%;
      height: auto;
    }
  }
}

.swiper-thumbnails-zoom,
.swiper-thumbnails {
  @include clearfix;
  padding: 10px 0;
  margin: 0 -10px;

  .swiper-thumb {
    width: 60px;
    height: 60px;
    padding: 10px;
    opacity: 0.4;
    float: left;
    cursor: pointer;

    &.is-active {
      opacity: 1;
    }

  }

}

.photo-gallery.matrix {
  img {
    margin-bottom: 30px;
  }
}

@include media-breakpoint-down(sm) {
  .swiper-button-next, .swiper-button-prev {
    height: 25px;
    @include background-size(auto, 100%);
  }
}

@include media-breakpoint-up(md) {
  .swiper-button-prev {
    left: 10px;
  }
  .swiper-button-next {
    right: 10px;
  }
}

@include media-breakpoint-up(lg) {
  .swiper-button-prev {
    left: -20px;
    margin-top: -52px;
  }
  .swiper-button-next {
    right: -20px;
    margin-top: -52px;
  }
}

@include media-breakpoint-up(xl) {
  .swiper-button-prev {
    left: -50px;
  }
  .swiper-button-next {
    right: -50px;
  }
}