.fancybox-slide--iframe .fancybox-content {
  width  : 800px;
  height : 600px;
  max-width  : 80%;
  max-height : 80%;
  margin: 0;
}

.fancybox-slide--ajax {
  > * {
    padding: 0;
    margin: 0;
  }
}

#overlay-content {
  max-width  : 800px;
  max-height : 600px;
  .overlay-content-text {
    padding: 20px 30px;
    @include media-breakpoint-up(md) {
      padding: 40px 30px;
    }
    @include media-breakpoint-up(lg) {
      padding: 40px 60px;
    }
    @include media-breakpoint-up(xl) {

    }
    & + .overlay-content-text {
      padding-top: 0px;
    }
  }
}