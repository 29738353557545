.staff-box-wrap {
  border: 2px solid $ci-primary;
  font-size: 15px;
  line-height: 1.42857;

  p:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .staff-box {
    hyphens: auto;
    .staff-photo {
      border-bottom: 2px solid $ci-primary;
      img {
        width: 100%;
        height: auto;
      }
    }
    .staff-infos {
      padding: 20px;


      h3 {
        font-size: 20px;
        line-height: 28px;
      }

      h4 {
        font-size: 16px;
      }

      h3 + div,
      h4 + div {
        padding-top: 20px;
      }

    }
  }
}

.phone, .mobile, .email, .fax {
  span {
    //@extend .glyphicon;
    margin-right: 8px;
  }
}

.phone {
  span {
    //@extend .glyphicon-phone-alt;
  }
}

.mobile span {
  //@extend .glyphicon-earphone;
}

.email {
  span {
    //@extend .glyphicon-envelope;
  }
}

.fax {
  span {
    //@extend .glyphicon-open-file;
  }
}

.equal-height-teaser {
  > div {
    height: 100%;
    margin-bottom: 0;
    > div {
      height: 100%;
      margin-bottom: 0;
    }
  }
  .staff-box-wrap {
    height: 100%;
    margin-bottom: 0;
  }
}
