.location-box-wrap {
  border: 2px solid $ci-primary;
  margin-bottom: 30px;
  font-size: 15px;
  line-height: 1.5;

  p {
    font-size: 15px;
    line-height: 1.5;
  }

}

.location-box {
  font-family: 'Roboto';
  .location-img {
    border-bottom: 2px solid $ci-primary;
    img {
      width: 100%;
      height: auto;
    }
  }

  .location-infos {
    padding: 20px;
    width: 100%;
    float: none;
    p:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    table {
      margin: 0 0 30px;
    }
    .infotext {
      padding-bottom: 20px;
    }
    > a {
      margin-top: 10px;
    }
  }
}

.location-box-full-width .location-box-wrap {
  border: 2px solid $ci-primary;
  padding: 20px;
  .location-box {
    @include make-row(30px);

    .location-img {
      @include make-col-ready(30px);
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(8);
      }

      border-bottom: 0;
    }
    .location-infos {
      @include make-col-ready(30px);
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }
    }
  }
}

.equal-height {
  margin-bottom: 30px;
  .location-box-wrap {
    height: 100%;
    margin: 0;
  }
}

.location-infos {
  span.fax {
    //@extend .glyphicon;
    //@extend .glyphicon-print;
    margin-right: 8px;
  }
}

.location-infos .email span {
  margin-right: 5px !important;
}

@include media-breakpoint-only(md) {

  .col-lg-4 .location-box-wrap{
    font-size:11px;
  }

  .btn-orange, .btn-blue {
    font-size: 12px;
    line-height: 24px;
    padding: 10px;
  }

  .location-box .location-infos{
    padding:8px;
  }

  .location-box-wrap p{
    font-size:12px;
  }

  .location-infos h3, .location-infos .h3{
    font-size:17px;
    line-height:24px;
  }
}