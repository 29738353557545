@charset "UTF-8";
@import "functions/hls";

:root {
  --ci-primary: hsl(207, 100%, 26%);
  --ci-primary-h: 207;
  --ci-primary-s: 100%;
  --ci-primary-l: 26%;

  --ci-secondary: hsl(25, 100%, 47%);
  --ci-secondary-h: 25;
  --ci-secondary-s: 100%;
  --ci-secondary-l: 47%;

  --ci-tertiary: hsl(207, 100%, 96%);
  --ci-tertiary-h: 207;
  --ci-tertiary-s: 100%;
  --ci-tertiary-l: 96%;

  --ci-link-color: hsl(25, 100%, 47%);
  --ci-link-color-h: 25;
  --ci-link-color-s: 100%;
  --ci-link-color-l: 47%;

  --ci-table-background-color: hsl(0, 0%, 86%);
  --ci-table-background-color-h: 0;
  --ci-table-background-color-s: 0%;
  --ci-table-background-color-l: 86%;

  --ci-grey-light: hsl(0, 0%, 91%);
  --ci-grey-light-h: 0;
  --ci-grey-light-s: 0%;
  --ci-grey-light-l: 86%;
}

$ci-primary: #{"var(--ci-primary)"};
$ci-secondary: #{"var(--ci-secondary)"};
$ci-tertiary: #{"var(--ci-tertiary)"};
$ci-link-color: #{"var(--ci-link-color)"};
$ci-table-background-color: #{"var(--ci-table-background-color)"};
$ci-grey-light: #{"var(--ci-grey-light)"};

// include partials (please add partials only in the include file)
@import "partials/include";