.immo-filter {
  @include make-row(20px);
  min-height: 80px;
  padding-bottom: 20px;
}

.object-list {
  .immo-filter {
    > div {
      @include make-col-ready(20px);
      @include media-breakpoint-up(md) {
        @include make-col(3);
      }
    }

    .bootstrap-select {
      width: 100% !important;

      .filter-option-inner-inner {
        font-size: 14px;
      }
    }
  }
}

.sorting-paging-top,
.sorting-paging-bottom {
  @include make-row(20px);
  padding-bottom: 30px;

  .sorting {
    @include make-col-ready(20px);
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }

    ul {
      @include reset_list;

      li {
        padding-right: 40px;
        display: inline-block;
        position: relative;
        span {
          width: 0px;
          height: 0px;
          -webkit-transform:rotate(360deg);
          border-style: solid;
          border-width: 0 5px 7px 5px;
          border-color: transparent transparent $sparda-black transparent;
          display: inline-block;
          position: absolute;
          top: 8px;
          right: 15px;
        }

        &.desc {
          span {
            border-width: 7px 5px 0 5px;
            border-color: $sparda-black transparent transparent transparent;
          }
        }
        &.active {
          color: $ci-secondary;
          span {
            border-color: transparent transparent $ci-secondary transparent;
          }
          &.desc {
            span {
              border-color: $ci-secondary transparent transparent transparent;
            }
          }
        }
      }
    }
  }

  nav {
    @include media-breakpoint-up(md) {
      @include make-col-ready(20px);
      @include make-col(6);
    }
  }
  .pagination {
    margin: 0;
    float: right;
  }
}

.object-list-items {
  > ul.list-items {
    @include reset_list;
    border-top: 1px solid $grey;
    padding: 0 0 40px;

    > li {
      border-bottom: 1px solid $grey;
      padding: 40px 0;
    }
  }
}


.object-item {
  .object-photo {
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .object-infos {
    @include clearfix;
    padding: 0;

    h3 {
      padding-bottom: 0;
    }
    .object-data ul {
      padding-bottom: 30px;
    }
    .expose-link a {
      color: #fff;
    }
  }
}

.reference-object {
  border: 2px solid $ci-primary;
  padding: 15px;
  margin-bottom: 40px;
  position: relative;

  .object-item {
    .object-label {
      position: absolute;
      background: $ci-primary;
      color: #fff;
      padding: 10px 40px;
      min-width: 200px;
      text-align: left;
      top: 40px;
      left: -1px;
      z-index: 1;
    }
    .object-infos {
      padding: 30px 0px 0;

      .object-logo, h3 {
        text-align: center;
        padding-bottom: 35px;
      }
      .object-logo {
        img {
          width: 100%;
          height: auto;
          max-width: 400px;
        }
      }
      .object-data {
        p {
          padding: 0;
          line-height: inherit;
        }
        ul {
          padding: 0 0 60px;
          line-height: 30px;
          border-top: 1px solid $border-color;

          li {
            padding: 5px 0;
            border-bottom: 1px solid $border-color;
            .label {
              width: 40%;
              padding-right: 5px;
            }
            .fact {
              margin: 0;
              width: 60%;
              padding: 0 0 0 5px;
            }
          }
        }
      }
      .expose-link {
        text-align: center;
        a {
          @extend .btn-blue;
          color: #fff;
          display: block;
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .reference-object {
    margin-bottom: 60px;
    .object-item {
      .object-infos {
        padding: 30px 0 0 0;
        .object-data {
          ul {
            li {
              .label {
                width: 40%;
                padding-right: 15px;
              }
              .fact {
                margin: 0 0 0 0;
                width:60%;
                padding: 0 0 0 15px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .tx-sparda-immobilien {
    .object-item {
      display: flex;
      .object-photo {
        min-width: 700px;
        max-width: 700px;
        width: 700px;
        padding-right: 40px;
        img {

        }

      }
      .object-infos {
        padding: 0;
      }
    }
  }

  .reference-object {
    margin-bottom: 60px;
    .object-item {
      .object-photo {
        width: 100%;
        padding-right: 0;
      }
      .object-infos {
        padding: 30px 0 0 0;
        .object-data {
          ul {
            li {
              .label {
                width: 140px;
                padding-right: 10px;
              }
              .fact {
                margin: 0;
                width:200px;
                padding: 0 0 0 10px;
              }
            }
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(xl) {
  .reference-object {
    margin-bottom: 60px;
    .object-item {
      .object-infos {
        padding: 30px 0 0 0;
        .object-data {
          ul {
            li {
              .label {
                width: 145px;
                padding-right: 15px;
              }
              .fact {
                margin: 0;
                width: 180px;
                padding: 0 0 0 15px;
              }
            }
          }
        }
      }
    }
  }
}

