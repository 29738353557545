@include media-breakpoint-down(md) {
  .nav-trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 65px;
    height: 75px;
    padding: 30px 20px;
    z-index: 2005;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    div {
      position: relative;
    }

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: $ci-primary;
      border-radius: 3px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 0px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      &:nth-child(2) {
        top: 6px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      &:nth-child(3) {
        top: 12px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }
    }
  }

  .main-nav-active {
    .nav-trigger {
      span {
        &:nth-child(1) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          top: -3px;
          left: 8px;
        }

        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }

        &:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
          top: 15px;
          left: 8px;
        }
      }
    }
  }

  #main-nav {
    opacity: 0;
    position: absolute;
    top: 0;
    left: -99999%;
    height: 0;
    padding: 100px 0 0;
    font-family: 'Roboto Condensed';
    ul {
      @include reset_list;
    }
    i {
      float: right;
      font-size: 22px;
    }
    ul li:not(.sub) i{
      display: none;
    }
    ul.nav {
      position: absolute;
      display: block !important;
      
      top: 0;
      width: 100%;
      height: 100%;
      overflow: scroll;
      padding: 100px 20px 0;
      letter-spacing: 0.5px;
      li {
        border-bottom: 1px solid rgba(255,255,255,.3);

        &:first-child {
          border-top: 1px solid rgba(255,255,255,.3);
        }

        a {
          display: block;
          color: #fff;
          padding: 15px 0;
          line-height: 30px;
          font-size: 18px;

          &:hover {
            color: #33bffb;
            background: none;
          }

        }

        &.sub {
          > a {
            padding-right: 34px;
            position: relative;
            span {
              @include glyphicon;
              @include glyphicon-menu-down;
              position: absolute;
              top: 15px;
              right: 0;
              margin: 5px 10px 0 0;
            }
            &:hover {
              span {
                @include glyphicon-menu-up;
              }
            }

            &.sf-with-ul {
              pointer-events: none;
            }
          }
          &.sfHover {
            > a {
              i {
                transform: rotate(180deg);
              }
              &.sf-with-ul {
                pointer-events: auto;
              }
            }
          }
        }


        &.is-active {
          > a {
            color: #33bffb;
          }
        }

        .sf-mega {
          ul:last-child {
            > li:last-child {
              border: none;
            }
          }
        }
        ul {
          font-family: $font-family-sans-serif;
          padding: 0 30px 0 15px;
          a {
            display: block;
            color: #fff;
            padding: 15px 0;
            line-height: 30px;
            font-size: 14px;
          }

          ul {
            padding: 0 30px 0 0;
            > li:last-child {
              border: none;
            }
            a {
              font-style: italic;
            }
          }
        }
      }
    }
  }

  .main-nav-active {
    #main-nav {
      opacity: 1;
      //@include transition-one-effekt(opacity);
      z-index: 2000;
      background: #fff;
      width: 100%;
      height: 100vh !important;
      bottom: 0;
      left: 0;
      background: $ci-primary;
    }
    .nav-trigger {
      span {
        background: #fff;
      }
    }
  }

}

@include media-breakpoint-up(md) {


}

@include media-breakpoint-up(lg) {
  .header-content {
    position: relative;
  }
  .header-nav {
    padding: 0;
    text-align: right;
    z-index: 5;
  }

  #main-nav {
    display: inline-block;
    vertical-align: top;
    font-family: $font-family-nav;
    font-weight: $font-weight-bold;
    font-size: 15px;
    letter-spacing: 1px;
    i {
      display: none;
    }
    ul {
      @include reset_list;
    }

    ul.nav {
      display: inline-block;

      > li {
        display: inline-block;
        text-transform: uppercase;
        a {
          color: $ci-primary;
          padding: 23px 6px 27px;
          position: relative;
          display: block;
          line-height: 17px;
          margin-bottom: -4px;
        }
        &.active {
          > a {
            color: $ci-secondary;
          }
        }
        &.sub {
          > a.sf-with-ul {
            > span {
              width: 0px;
              height: 0px;
              -webkit-transform:rotate(360deg);
              border-style: solid;
              border-width: 0 8px 10px 8px;
              border-color: transparent transparent $ci-secondary transparent;
              position: absolute;
              bottom: 0;
              left: 50%;
              margin-left: -8px;
              opacity: 0;

            }
          }
          &.sfHover {
            > a.sf-with-ul {
              text-decoration: underline;
              > span {
                opacity: 1;
                -webkit-transition: opacity 0.3s;
                transition: opacity 0.3s;
                //-webkit-transition-delay: 0.1s;
                //transition-delay: 0.1s;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
              }
            }
          }
        }

        > .sf-mega {
          background: $ci-secondary;
          padding: 2px 20px 40px;
          text-align: left;
          font-family: $font-family-sans-serif;
          font-weight: $font-weight-regular;
          font-size: 16px;
          width: 100%;
          position: absolute;
          left: 0;
          overflow: hidden;
          display: none;
          top: 100%;
          z-index: 99;

          > ul {
            width: 25%;
            float: left;
            text-transform: none;
            padding: 0 20px;
            li {
              float: none;
              width: 100%;
              a {
                color: #fff;
                border-bottom: 1px solid rgba(255,255,255,.3);
                padding: 15px 0;
                line-height: 27px;
                min-height: 84px;
                span {
                  display: none !important;
                }
                &:hover {
                  text-decoration: none;
                  span {
                    display: none !important;;
                  }
                }
              }
              &.active {
                > a {
                  color: $ci-primary;
                }
              }
            }

            ul {
              padding-top: 15px;
              display: block !important;
              opacity: 1 !important;
              width: 100%;
              float: none;
              li {
                float: none;
                width: 100%;
                a {
                  padding: 7px 0;
                  border: none;
                  font-size: 15px;
                  line-height: $line-height-base;
                  min-height: 22px;
                }
                span {
                  display: none;
                }
              }

            }

          }
          > li {
            a {
              color: #fff;

            }
          }

        }

      }

    }
  }
  .shrinked-header {
    .header-nav {

    }
    #main-nav {
      ul.nav {
        > li {
          a {

          }

          > ul {
            padding: 2px 20px 40px;
            > li {
              padding: 0 20px;
              a {
                padding: 15px 0;
              }

              ul {
                padding-top: 15px;
                li {
                  a {
                    padding: 7px 0;
                    border: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  #main-nav {
    font-size: 17px;
    letter-spacing: 2px;
    ul.nav {

      > li {
        a {
          padding: 23px 8px 27px;
        }
      }
    }
  }
}

/* https://www.iconfinder.com/iconsets/flags_gosquared */

ul{
  .menu-country-flag{
    width: 20px;
    height: 15px;
    position:absolute;
    top:22px;
    left:-25px;
    background-size: contain;
    background-repeat: no-repeat;
    @include media-breakpoint-up(lg) {
      top:10px;
    }
  }
}