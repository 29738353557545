.social-nav {
  display: none;
}
.main-nav-active {
  .social-nav {
    position: absolute;
    top: 0;
    right: 0;
    padding: 25px 20px 25px 80px;
    z-index: 2001;
    display: block;
    height: 75px;
    background: $ci-primary;
  }
}

.social-nav {
  ul {
    @include reset_list;
    text-align: right;
    li {
      border-right:1px solid rgba(255,255,255,.5);
      display: inline-block;

      &:last-child {
        border: none;
      }
    }

    a {
      color: #fff;
      line-height: 25px;
      padding: 0 10px;
    }
    i {
      @extend .icon-font;
      margin: 0;
    }
    .facebook i {
      @extend .icon-facebook;
    }
    .twitter i {
      @extend .icon-twitter;
    }
    .youtube i {
      @extend .icon-youtube-play;
    }
    .instagram i {
      @extend .icon-instagram;
    }
  }
}

@include media-breakpoint-up(lg) {
  .social-nav {
    display: block;
    position: fixed;
    top: 50%;
    left: 0;
    padding: 0;
    z-index: 2001;
    background: transparent;
    margin-top: -95px;
    ul {
      text-align: left;
      position: relative;
      padding: 34px 0;
      width: 34px;

      li {
        background: $ci-secondary;
        border-right: none;
        display: block;
        padding: 0 5px;

        a {
          width: 24px;
          text-align: center;
          display: block;
          border-bottom: 1px solid rgba(255, 255, 255, .5);
          padding: 10px 0;
          line-height: 1;
          i {
            margin: 0;
          }

        }

        &:first-child {
          &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: " ";
            width: 0px;
            height: 0px;
            -webkit-transform:rotate(360deg);
            border-style: solid;
            border-width: 34px 0 0 34px;
            border-color: transparent transparent transparent $ci-secondary;
          }
        }

        &:last-child {
          border: none;
          &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: " ";
            width: 0px;
            height: 0px;
            -webkit-transform:rotate(360deg);
            border-style: solid;
            border-width: 34px 34px 0 0;
            border-color: $ci-secondary transparent transparent transparent;
          }
          a {
            border-bottom: 0;
          }
        }
      }
    }
  }
}