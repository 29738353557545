.teaser-boxes {
  .col-lg-3 {
    margin-bottom: 30px;
  }
}
.equal-height-teaser {
  margin-bottom: 30px;
  >div {
    height: 100%;
    margin: 0px !important;

  }
}

.equal-height-teaser {
  .teaser-box {
    height: 100%;
  }
}

.teaser-box {
  font-size: 15px;
  background: $ci-primary;
  position: relative;
  hyphens: auto;
  a {
    display: block;
    color: #fff;

    h2, p {
      color: #fff;
    }
    &:hover {
      text-decoration: none;
    }
  }

  .teaser-box-img {
    overflow: hidden;
    background: #000;
    img {
      @extend .img-fluid;
      opacity: 1;
      -webkit-transform: translate(0px, 0);
      -webkit-transition: -webkit-transform 0.3s ease-in-out;
      -moz-transform: translate(0px, 0);
      -moz-transition: -moz-transform 0.3s ease-in-out;
      transform: translate(0px, 0);
      transition: all 0.3s ease-in-out;
    }
  }

  &:hover {
    .teaser-box-img {
      img {
        -moz-transform: scale(1.2);
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: .5;
      }
    }
  }

  .teaser-box-content {
    color: #fff;
    padding: 20px 20px 40px;
    border-top: 4px solid $ci-secondary;

    h2 {
      font-size: 20px;
      line-height: 30px;
      padding-bottom: 10px;
    }

    p {
      padding: 0 0 1.42857em;
      line-height: 1.42857em;
    }

    .teaser-box-link {
      position: absolute;
      bottom: 20px;
      left: 25px;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: $font-weight-bold;
      a {
        color: $white;
        &:hover {
          color: $white;
        }
      }
    }
  }
}

.link-box {
  padding-bottom: 30px;
  font-size: 15px;
  overflow: hidden;
  a {

    .link-box-img {
      padding-top: 83.3333%;
      background: #000;
      @include background-size(100%, auto);
      position: relative;
      z-index: 1;
      .link-box-bgimg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: all 0.3s ease-in-out;
        background-size: cover;
      }
      .box-text {
        margin-bottom: -100%;
        position: absolute;
        left: 0px;
        bottom: 0;
        width: 100%;
        transition: all 0.3s ease-in-out;
        color: #fff;
        padding: 10px 15px;

        p {
          margin: 0;
          padding: 0;
          line-height: 1.42857;
        }

      }
    }
    .link-box-link {
      display: block;
      padding: 10px 15px;
      line-height: 30px;
      background: $ci-secondary;
      color: #fff;
      font-size: 15px;
      font-weight: $font-weight-bold;
      position: relative;
      z-index: 5;
      i {
        float: right;
        line-height: 30px;
      }
    }
    &:hover {
      text-decoration: none;

      .link-box-img {
        .link-box-bgimg {
          opacity: .5;
          transition: all 0.3s ease-in-out;
        }

        .box-text {
          margin-bottom: 0;
          transition: all 0.5s ease-in-out;
        }
      }
    }
  }
}

.special-image-teaser {
  padding: 0;
  position: relative;
  margin: 60px 0;
  .special-teaser-image {
    padding-bottom: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-size: cover;
  }

  .special-teaser-content-wrap {
    margin: 0 auto;
    padding: 60px 20px;
    @include flexbox;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    z-index: 2;
    position: relative;
    .special-teaser-content {
      max-width: 750px;
      text-align: center;
      position: relative;
      z-index: 2;

      p:last-child {
        padding-bottom: 0;
      }

    }
    .source{
      position: absolute;
      bottom:15px;
      right:15px;
      font-size:12px;
      color: #5c5c5c;
    }
  }

  &.set-opacity {
    .special-teaser-content-wrap {
      background: rgba(255,255,255,.7);
    }
  }

  &.parallax-effect {
    min-height: 400px;
    background: transparent;
    .special-teaser-content-wrap {
      position: absolute;
      width: 100%;
      height: 101%;
    }
  }
}

@include media-breakpoint-up(md) {
  .teaser-boxes {
    .col-lg-3 {
      margin-bottom: 0px;
    }
  }


}
@include media-breakpoint-up(lg) {
  .teaser-box {
    .teaser-box-content {
      h3 {
        font-size: 20px;
      }
      p {

      }
    }
  }


  .special-image-teaser {
    margin: 130px 0;

    .special-teaser-content-wrap {
      margin: -1px auto 0 auto;
      padding: 130px 0;
    }
  }

  .link-boxen + .special-image-teaser {
    margin: 100px 0;
  }

}





// Off-canvas navigation

.off-canvas-menu {
  position: fixed;
  top: 25%;
  right: 0;
  z-index: 1600;

  .off-canvas-links {
    position: absolute;
    right: -400px;
    min-height: 325px;
    max-width: 400px;
    min-width: 330px;
    width: auto;
    border: 1px solid #fff;
    border-right: none;
    padding: 40px 103px 40px 30px;
    background-color: $ci-primary;
    background-repeat: no-repeat;
    background-position: center bottom;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 10px;
        line-height: 24px;
        color: #fff;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    a {
      color: #fff;
    }

    .lg-share {
      position: absolute;
      bottom: 30px;
    }
  }

  .off-canvas-nav-trigger {
    position: absolute;
    right: 0;
    width: 73px;
    height: 325px;
    padding: 20px 15px;
    z-index: 2005;
    border: 1px solid #fff;
    border-right: none;
    background-color: $ci-primary;
    background-repeat: no-repeat;
    background-position: center 100px;
    @include background-size(auto, 203px);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    div {
      position: relative;
      width: 44px;
      height: 44px;
    }

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #fff;
      border-radius: 0;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 4px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      &:nth-child(2) {
        top: 15px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      &:nth-child(3) {
        top: 26px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .off-canvas-nav-trigger {
      width: 73px;
      height: 73px;
    }
  }
}

.off-canvas-menu-active {
  .off-canvas-links {
    right: 0;
  }

  .off-canvas-nav-trigger {
    span {
      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: -3px;
        left: 8px;
      }

      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 27px;
        left: 8px;
      }
    }
  }
}


.mb-none-space {
  margin-bottom: 0;
}

.mb-sm-space {
  margin-bottom: $sm-space;
}

.mb-md-space {
  margin-bottom: $md-space;
}

.mb-lg-space {
  margin-bottom: $lg-space;
}


.mt-none-space {
  margin-top: 0;
}

.mt-sm-space {
  margin-top: $sm-space;
}

.mt-md-space {
  margin-top: $md-space;
}

.mt-lg-space {
  margin-top: $lg-space;
}

.hidden-xs {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.hidden-sm {
  @include media-breakpoint-only(md) {
    display: none;
  }
}

.hidden-md {
  @include media-breakpoint-only(lg) {
    display: none;
  }
}

.hidden-lg {
  @include media-breakpoint-only(xl) {
    display: none;
  }
}

.hidden-xl {
  @include media-breakpoint-up(xl) {
    display: none;
  }
}
