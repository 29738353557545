#immo-directsearch {
  .immo-directsearch-box {
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    border-bottom: 4px solid $ci-secondary;
    padding: 15px;
    h2 {
      padding: 0 0 20px;
    }

    .immo-filter {
      padding-bottom: 0;
    }
    .select-immo-type,
    .select-immo-objects {
      width: 100%;
      padding: 0 10px 20px;
      min-height: 62px;
      label {
        display: none;
      }
      select {
        border: 1px solid #d9d9d9;
        width: 100%;
        padding: 5px 10px;
        line-height:30px;
        height: 42px;
        option::before {
          color: black !important;
          position: absolute;
          top: 0;
          right: 0;
          font-family: "Font Awesome 6 Free";
          content: "f0d7";
        }
      }
    }

    .submit-button {
      position: relative;

      input {
        @extend .btn-orange;
        border: 0;
        width: 100%;
        text-align: center;
      }
    }

    .corner {
      width: 0px;
      height: 0px;
      -webkit-transform: rotate(360deg);
      border-style: solid;
      border-width: 0 11px 15px 0;
      border-color: transparent rgba(255,255,255,0.9) transparent transparent;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

#stage {
  #immo-directsearch {
    position: absolute;
    top: 50px;
    left: 20px;
    right: 20px;
    padding: 0;
    z-index: 100;
  }
}

#hero-content {
  #immo-directsearch {
    .immo-directsearch-box {
      .corner {
        border-color: transparent rgba(255,255,255,1) transparent transparent;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #immo-directsearch {
    .immo-directsearch-box {
      h2 {
        padding: 0 0 15px;
      }

      .select-immo-type,
      .select-immo-objects {
        width: 100%;
        padding: 0 10px 15px;
        min-height: 45px;
        .dropdown-toggle {
          line-height: 24px;
          padding: 2px 25px 2px 10px;
        }
      }

      .submit-button {
        input {
          line-height: 24px !important;
          padding: 2px 0 !important;
        }
      }
    }
  }
}
@include media-breakpoint-up(md) {
  #stage #immo-directsearch {
    position: absolute;
    top: auto;
    bottom: 40px;
    left: auto;
    right: 20px;
    width: 230px;
    padding: 0;
    z-index: 100;
  }
  #hero-content {
    #immo-directsearch {
      width: 750px;
      margin: 0 auto;
      padding: 0;
      position: relative;
      .immo-directsearch-box {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 230px;
        z-index: 100;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #stage #immo-directsearch {
    position: absolute;
    bottom: 10%;
    right: 10%;
    padding: 0;
    z-index: 100;
  }
  #hero-content {
    #immo-directsearch {
      width: 970px;
      padding: 0 15px;
      .immo-directsearch-box {
        right: 35px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  #hero-content {
    #immo-directsearch {
      width: 1170px;
    }
  }
}