.quick-contact-trigger-box span,
.quick-contact-trigger {
  //@extend .glyphicon;

  padding-right: 10px;
  cursor: pointer;
}

.header-wrap {
  .quick-contact-trigger {
    //@extend .glyphicon-envelope;
  }
}

#quick-contact {
  height: 0;
  overflow: scroll;
  @include transition-effekt;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $ci-primary;
  z-index: 1999;
}

.quick-contact-content {
  @extend .container;

  padding-top: 190px;
  padding-bottom: 100px;
  color: #fff;
  a {
    color: $ci-secondary;
    &:hover {
      color: $ci-link-color;
    }
  }
  .quick-contact-trigger {
    //@extend .glyphicon-remove;
    width: 30px;
    height: 30px;
    float: right;
    span i:before {
      font-size: 25px;
    }
  }

  h2 {
    text-align: center;
  }
  .tx-powermail {
    max-width: 460px;
    padding: 0 15px;
    margin: 0 auto;
    .powermail_fieldwrap_type_radio .powermail_field_error_container {
      display: none;
    }
  }
  form h2 {
    display: none;
  }
  .tx-powermail {
    label {
      color: #fff;
    }
    .powermail_submit {
      @extend .btn-orange;
      background-color: $ci-secondary !important;
      border: 2px solid $ci-secondary;
      width: 100%;
      text-align: center;
      margin-top: 0px;
      &:hover {
        background-color: transparent !important;
        border: 2px solid $white;
      }
    }
    .powermail_fieldwrap_type_submit .powermail_submit {
      margin-top: 20px;
    }
  }
}

.quick-contact-open {
  .header-wrap {
    .quick-contact-trigger {
      color: $ci-link-color;
    }
  }

  #quick-contact {
    height: 100vh;
    @include transition-effekt;
  }
}