#nav-waypoint {
  position: absolute;
  top: 70px;
}

#page1 #nav-waypoint {
  top: 90px;
}

.header-wrap {
  position: fixed;
  width: 100%;
  z-index: 2000;
  top: 0;
}

header.header {
  border-bottom: 4px solid $ci-primary;
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }


  background: #fff;
  @include transition-effekt;

  .header-container {
    @extend .container;
  }

}

.main-nav-active {
  header.header {
    background: $ci-primary;
  }
}

.header-background {
  header {
    background: #fff;
    @include transition-effekt;
  }
}

.spi-logo {
  padding: 15px 0;
  margin: 0 65px;
  z-index: 10;
  position: relative;
  a {
    display: block;
    height: 45px;
    background: transparent url('#{$logo_url}') no-repeat center top;

    @include background-size(auto, 45px);
  }
}

.header-nav {
  z-index: 5;
}

.quick-contact-nav {
  position: absolute;
  right: 0;
  margin-top: 0;

}

.header-phone-no {
  overflow: hidden;
  display: block;
  width: 100%;
  position: relative;
  padding-left: 35px;

  .header-phone-corner {
    display: inline-block;
    position: absolute;
    left: 0;

    width: 0px;
    height: 0px;
    -webkit-transform:rotate(360deg);
    border-style: solid;
    border-width: 0 35px 35px 0;
    border-color: transparent $ci-primary transparent transparent;
    float: left;
  }
  .phone-number {
    width: 100%;
    min-width: 242px;
    display: inline-block;
    background: $ci-primary;
    color: #fff;
    font-size: 15px;
    line-height: 35px;
    height: 35px;
    float: right;
    padding: 0 15px 0 10px;

    span.dial-number {
      width:169px;
      display:inline-block;
    }

    span {
      margin-right: 5px;
    }

  }
  .dial-number {
    a {
      color: #fff;
      padding-left: 8px;

      &:before {
        left: -8px;
        /*
        @extend .glyphicon;
        @extend .glyphicon-earphone;
        */
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.newsletter-box {
  margin-left: 35px;
  .newsletter-box-corner {
    width: 0px;
    height: 0px;
    -webkit-transform:rotate(360deg);
    border-style: solid;
    border-width: 0 23px 23px 0;
    border-color: transparent $ci-secondary transparent transparent;
    float: left;
  }
  .newsletter-box-link {
    background: $ci-secondary;
    margin-left: 23px;
    font-size: 13px;
    line-height: 23px;
    padding: 0 20px 0 10px;
    a {
      color: #fff;
      display: block;
      text-align: right;
    }
  }
}


.top-padding {
  padding-top: 30px;
}
.bottom-padding {
  padding-bottom: 30px;
}

@include media-breakpoint-up(lg) {
  .spi-logo {
    float: left;
    padding: 36px 0 25px;
    @include transition-effekt;
    margin: 0;
    width: 150px;
    a {
      display: block;
      width: 150px;
      height: 45px;
      background: transparent url($logo_url) no-repeat left bottom;

      @include background-size(auto, 100%);
      @include transition-effekt;
    }
  }

  .header-nav {
    padding-top: 43px;
    position: relative;
    @include transition-effekt;
  }

  .shrinked-header {
    .spi-logo {
      padding: 15px 0;
      @include transition-effekt;
      a {
        height: 33px;
        @include background-size(auto, 100%);
      }
    }
    .header-nav {
      padding-top: 0px;
      @include transition-effekt;
    }
  }

  .header-phone-no {
    padding-left: 50px;
  }
  .quick-contact-nav {
    margin-top: 0;
  }

  .header-phone-no {
    .phone-number {
      line-height: 40px;
      height: 56px;
      padding: 10px 15px 6px 10px;
    }
    .header-phone-corner {
      border-width: 0 50px 56px 0;
    }
  }
  .newsletter-box {
    margin-left: 50px;
    .newsletter-box-corner {
      border-width: 0 23px 28px 0;
    }
    .newsletter-box-link {
      font-size: 15px;
      line-height: 28px;
    }
  }
}
#page185 .quick-contact-nav {
  .header-phone-no {
    display:none;
    background-color:#c42a34;
  }
  .newsletter-box .newsletter-box-link{
    background:#c42a34;
  }
}

@include media-breakpoint-up(xl) {

}
