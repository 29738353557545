
.container-cricle {

  .fact {
    font-size: 18px;
    line-height: 25px;
    background-color: $ci-primary;
    margin: 0 auto 30px;
    height: 250px;
    width: 250px;
    text-align: center;
    border: 15px solid #e8f1f9;
    display: block;
    border-radius: 50%;
    color: #fff;
    padding: 15px;
    @include flexbox;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    flex-direction: column;
    span {
      width: 100%;
      display: block;
    }

  }
}
