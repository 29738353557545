.row .event {
  @include media-breakpoint-up(md) {
    @include make-col-ready(30px);
    @include make-col(6);
  }
}

.news-list-view {
  .row {
    margin-bottom: 30px;
  }

  .news-img-wrap {
    width: 220px;
    float: left;
    a {
      display: block;
    }

    img {
      width: 100%;
      height: auto;
    }
    .no-media-element {
      padding: 20px 0;
      display: block;

      img {
        width: 230px;
      }

    }
  }

  .event-infos {
    margin-left: 250px;

    h3 {
      padding-bottom: 25px;
    }

  }

  .more-link {
    a {
      display: inline-block
    }
  }

  .equal-height {
    article {
      //height: 100%;
      //position: relative;
      //border: 2px solid $ci-primary;
    }
  }
}

.event-date-time {
  padding-bottom: 30px;
  .event-label {
    width: 100px;
    float: left;
    font-weight: bold;

  }
  .event-date {
    margin-left: 100px;
  }
}

.news-single {
  .news-img-wrap {
    img {
      width: 100%;
      height: auto;
    }
  }
  .news-list-date {
    padding-bottom: 30px;
  }
  .event-date-time {
    padding-top: 30px;
  }
}

