@charset "UTF-8";



/*********************************************************/
/*                       KE-SEARCH                       */
/*********************************************************/

.tx-kesearch-pi1 {
  fieldset {
    //@include make-row(30px);
  }

  label {
    color: #999;
    line-height: 30px;
    padding: 20px 0 0;
    display: block;
    font-weight: normal;

    &.label-cb-radio {
      margin: 10px 0 0 0;
    }
  }

  .form-element-field {
    display: inline-flex;

    .radio {
      width: 80px;
    }
  }

  .radio label, .checkbox-inline label {
    padding-top: 0px;
    margin-right: 20px;
  }

  .checkbox-inline {
    margin-left: 0px !important;
  }

  input[type=email],
  input[type=text],
  input[type=password],
  input[type=search],
  textarea {
    background: #EBEBEB !important;
    height: 60px;
    border: 2px solid #EBEBEB;
    border-radius: 2px;
    font-size: 24px;
    line-height: 30px;
    padding: 12px;
    width: 100%;
    font-family: $font-family-serif;

    // wenn aktive state unbedingt auch bei ausgefüllt erwünscht, gibt es mehrere Möglichkeiten
    // z.B. alle Felder als required (required="required") kennzeichnen und :valid
    // oder javascript Lösung:
    //https://stackoverflow.com/questions/16952526/detect-if-an-input-has-text-in-it-using-css-on-a-page-i-am-visiting-and-do-no

    &:active, &:focus {
      background: #fff;
      border-color: #D9D9D9;
      outline: 0;
    }

    &.parsley-error {
      background: #fff;
      border-color: #D9D9D9;
      outline: 0;
    }
  }

  .checkbox.parsley-error {
    background: transparent;
    color: #f00000;
  }

  .parsley-error {
    label, label a {
      color: #f00000;
    }

    input[type=text], textarea {
      background: #fff;
      border-color: #f00000;
      color: #f00000;
      outline: 0;
    }
  }
}



/*********************************************************/
/*                      NEWSLETTER                       */
/*********************************************************/

.newsletter {
  fieldset {
    margin-left: -15px;
    margin-right: -15px;
  }

  .form-element-field {
    display: flex;
    flex-wrap: wrap;
  }

  .checkbox-inline {
    width: 270px;
  }

  .radio {
    width: 190px;
  }
}



/*********************************************************/
/*                    POWERMAIL FORMS                    */
/*********************************************************/

.powermail_form {
  h2 {
    @include make-col-ready(30px);
    @include make-col(12);
    padding-bottom: 10px;
  }

  label {
    color: #999;
    line-height: 30px;
    padding: 20px 0 0;
    display: block;
    font-weight: normal;
    
    &.label-cb-radio {
      margin: 10px 0 0 0;
    }
  }
  
  .powermail_fieldset {
    position: relative;
    @include make-row(30px);

    .powermail_field_error,
    div.error {
      background-color: transparent !important;
  
      label, a {
        color: #A94442 !important;
        font-weight: bold;
      }
    }
  }

  .powermail_fieldwrap {
    order: 5;
    @include make-col-ready(30px);
    @include make-col(12);
  }

  .powermail_fieldwrap_anrede .form-element-field {
    display: inline-flex;
    
    .radio {
      width: 80px;
      
      .powermail_field_error_container {
        display: none;
      }
    }
  }

  .radio label,
  .checkbox-inline label {
    padding-top: 0px;
    margin-right: 20px;
  }

  .checkbox-inline {
    margin-left: 0px !important;
  }

  input[type=email],
  input[type=text],
  input[type=password],
  input[type=search],
  textarea {
    background: #EBEBEB !important;
    height: 60px;
    border: 2px solid #EBEBEB;
    border-radius: 2px;
    font-size: 24px;
    line-height: 30px;
    padding: 12px;
    width: 100%;
    font-family: $font-family-serif;

    // wenn aktive state unbedingt auch bei ausgefüllt erwünscht, gibt es mehrere Möglichkeiten
    // z.B. alle Felder als required (required="required") kennzeichnen und :valid
    // oder javascript Lösung:
    //https://stackoverflow.com/questions/16952526/detect-if-an-input-has-text-in-it-using-css-on-a-page-i-am-visiting-and-do-no

    &:active, &:focus {
      background: #fff;
      border-color: #D9D9D9;
      outline: 0;
    }

    &.parsley-error {
      background: #fff;
      border-color: #D9D9D9;
      outline: 0;
    }
  }

  input[type=email],
  input[type=text],
  input[type=password],
  input[type=search] {
    height: 60px;
  }
  textarea {
    height: 180px;
  }

  .checkbox.parsley-error {
    background: transparent;
    color: #f00000;
  }

  .parsley-error,
  .powermail_error {
    label, label a {
      color: #f00000;
    }

    input[type=text],
    textarea {
      background: #fff;
      border-color: #f00000;
      color: #f00000;
      outline: 0;
    }
  }

  .powermail_fieldwrap_type_check,
  .powermail_fieldwrap_type_radio {
    .form-element-label {
      display: none;
    }
    .form-element-field {
      label {
        line-height: 1.42857;
      }
    }
  }

  .powermail_fieldwrap_type_check {
    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }
  }

  .powermail_fieldwrap_type_radio {
    .form-element-field {
      .radio {
        .powermail-errors-list {
          width: auto;
          float: left;

          li {
            width: max-content;
          }
        }
      }
    }
  }

  .powermail_fieldwrap_type_input,
  .powermail_fieldwrap_type_captcha {
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }
  }

  .powermail_fieldwrap_anrede,
  .powermail_fieldwrap_lastname,
  .powermail_fieldwrap_firstname {
    @include media-breakpoint-up(lg) {
      order: 1;
    }
  }

  .powermail_fieldwrap_type_check,
  .powermail_fieldwrap_city {
    @include media-breakpoint-up(lg) {
      @include make-col-extoffset(4, 12);
    }
  }

  .powermail_fieldwrap_streetaddress {
    @include media-breakpoint-up(lg) {
      @include make-col-extoffset(8, 12);
    }
  }

  .powermail_fieldwrap_message {
    label {
      position: relative;
    }

    @include media-breakpoint-up(lg) {
      order: 2;
      @include make-col(4);

      display: flex;
      position: relative;
      flex-direction: column;
      align-self: self-start;
      right: 0;
      top: initial;

      > div {
        margin-top: 0.5rem;
        top: 50px;
        position: absolute;
        width: calc(100% - 30px);
        
        textarea {
          height: 280px;
        }
      }
    }
  }

  .powermail_fieldwrap_type_submit,
  .powermail_fieldwrap_absenden {
    padding: 70px 15px 0 15px;
  }

  .powermail_fieldwrap_datenschutzhinweis,
  .powermail_fieldwrap_zustimmungagb,
  .powermail_fieldwrap_widerrufserklaerung,
  .powermail_fieldwrap_type_captcha {
    .powermail_field_error_container {
      display: none;
    }
  }


  /* "Quick-Conact"-Form */
  &.powermail_form_2 {
    .powermail_fieldwrap_type_input,
    .powermail_fieldwrap_type_textarea,
    .powermail_fieldwrap_type_html,
    .powermail_fieldwrap_type_check,
    .powermail_fieldwrap_type_radio {
      @include make-col-ready(30px);
      @include make-col(12);
    }
  }
}

.typo3-messages {
  margin: 0;
  padding: 0;
}



/*********************************************************/
/*                    RENDITERECHNER                     */
/*********************************************************/

#renditerechner {
  .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
  }

  .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)
  }

  .form-control::-moz-placeholder {
    color: #999;
    opacity: 1
  }

  .form-control:-ms-input-placeholder {
    color: #999
  }

  .form-control::-webkit-input-placeholder {
    color: #999
  }

  .form-control::-ms-expand {
    border: 0;
    background-color: transparent
  }
}



/*********************************************************/
/*                LOGIN - Partnerbereich                 */
/*********************************************************/

.type-felogin_login {
  h2,
  h3,
  h4 {
    padding-bottom: 10px;
  }

  fieldset {
    @include make-row(30px);
  }

  label {
    color: #999;
    line-height: 30px;
    padding: 20px 0 0;
    display: block;
    font-weight: normal;
  }

  input[type=text],
  input[type=password] {
    background: #EBEBEB;
    height: 60px;
    border: 2px solid #EBEBEB;
    border-radius: 2px;
    font-size: 24px;
    line-height: 30px;
    padding: 12px;
    width: 100%;
    font-family: $font-family-serif;
    
    &:active, &:focus {
      border-color: #D9D9D9;
      outline: 0;
    }
  }

  .felogin_type_input {
    &.felogin_newpassword1,
    &.felogin_newpassword2,
    &.felogin_forgot-email,
    &.felogin_user,
    &.felogin_password {
      @include make-col-ready(30px);
      @include media-breakpoint-up(sm) {
        @include make-col(12);
      }
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }
    }
  }

  .felogin_type_submit {
    clear: both;
    width: 100%;
    padding: 40px 0 40px 0;

    .felogin_submit {
      @extend .btn-large-blue;
    }
  }

  .felogin-hidden {
    display: none;
  }
}

.alert {
  padding: 5px 10px;
  border-radius: 0px;
  margin: 5px 0 20px 0;
  list-style-type: none;

  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

@include media-breakpoint-up(lg) {
  .felogin_messages {
    width: 33%;
  }
}