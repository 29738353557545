.postit-holder {
  position: relative;
  height: 0;
  padding-top: 0;
  padding-right: 15px;
  padding-bottom: 20px;
}

.postit-wrapper {
  display: none;
  position: absolute;
  width: auto;
  height: auto;
  left: auto;
  top: auto;
  z-index: 1998;
}

.postit {
  border-radius: 1px 0 4px 0;
  background: #ffff69;
  position: relative;
  width: 220px;
  height: 200px;
  font: 400 28px/34px $font-family-postit, Helvetica, sans-serif;
  padding: 20px;
  display: block;
  margin: 24px 0 0 15px;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.7) !important;
  text-shadow: 1px 1px 3px #aaa;
  transform: rotate(8deg);
  border-bottom-left-radius: 10px 10px;
  text-decoration: none !important;

  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3));
  background: linear-gradient(54deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 5%,
          #f3f673 5%,
          #ffffa5 97%,
          rgba(255, 255, 165, 0) 99%,
          rgba(255, 255, 165, 0) 100%
  );

  animation-name: backknick;
  animation-duration: 350ms;
  animation-timing-function: ease;
  animation-fill-mode: both;
  animation-delay: 0;

  &:hover {
    animation-name: knick;
    animation-duration: 300ms;
    animation-timing-function: ease;
    animation-fill-mode: both;
    animation-delay: 0;
    filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.37));
  }

  &:hover {
    text-decoration: none;
    transition-delay: 0.1s;
    background: linear-gradient(54deg,
            rgba(255, 255, 165, 0) 0%,
            rgba(255, 255, 165, 0) 7%,
            rgba(255, 255, 165, 1) 8%,
            rgba(255, 255, 165, 1) 97%,
            rgba(255, 255, 165, 0) 99%,
            rgba(255, 255, 165, 0) 100%
    );
    .curl {
      width: 24px;
      height: 34px;
    }
  }


  &:before {
    content: " ";
    position: absolute;
    top: -2px;
    right: 1px;
    width: 0;
    height: 0;
    border-top: 10px solid #FFFFA5;
    border-left: 9px solid transparent;
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.2));
    transform: rotate(165deg);
  }

  .curl {
    width: 16px;
    height: 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(234deg, #ffff69, #FFFFA1 25%, #fbfb9e 38%, #dedd87 44%, #e4e3b580 50%, rgba(243, 245, 182, 0.5) 50%, rgba(255, 255, 255,  0.05) 50%, transparent);
    transition: all .3s ease;

    &:after,
    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      left: 50%;
      bottom: 50%;
      width: 50%;
      max-width: 283px;
      max-height: 100px;
      height: 35%;
      -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
      box-shadow: 2px 8px 12px rgba(50, 50, 50, 0.2);
      -webkit-transform: skew(-10deg) rotate(-6deg);
      transform: skew(-10deg) rotate(-6deg);
    }

    &:after {
      left: auto;
      right: 18%;
      bottom: auto;
      top: -1%;
      -webkit-transform: skew(-15deg) rotate(-84deg);
      transform: skew(-15deg) rotate(-84deg);
    }
  }
}

a.postit {
  cursor: pointer;
}



@-webkit-keyframes knick {
  from {
    background: linear-gradient(54deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 5%,
            #f3f673 5%,
            #ffffa5 97%,
            rgba(255, 255, 165, 0) 99%,
            rgba(255, 255, 165, 0) 100%
    );
  }
  to {
    background: linear-gradient(54deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 7.3%,
            #f3f673 7.3%,
            #ffffa5 97%,
            rgba(255, 255, 165, 0) 99%,
            rgba(255, 255, 165, 0) 100%
    );
  }
}




@-webkit-keyframes backknick {
  from {
    background: linear-gradient(54deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 7.3%,
            #f3f673 7.3%,
            #ffffa5 97%,
            rgba(255, 255, 165, 0) 99%,
            rgba(255, 255, 165, 0) 100%
    );
  }
  to {
    background: linear-gradient(54deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 5%,
            #f3f673 5%,
            #ffffa5 97%,
            rgba(255, 255, 165, 0) 99%,
            rgba(255, 255, 165, 0) 100%
    );
  }
}



@include media-breakpoint-up(md) {
  .postit-wrapper {
    display: block;
  }
}

@include media-breakpoint-up(lg) {
  .postit {
    width: 270px;
    height: 230px;
    font: 400 38px/40px $font-family-postit, Helvetica, sans-serif;

    .curl {
      width: 19px;
      height: 26px;
    }

    &:hover {
      .curl {
        width: 30px;
        height: 40px;
      }
    }
  }
}


@include media-breakpoint-up(xl) {
  .postit {
    width: 300px;
    height: 250px;
    font: 400 48px/45px $font-family-postit, Helvetica, sans-serif;

    .curl {
      width: 23px;
      height: 29px;
    }

    &:hover {
      .curl {
        width: 34px;
        height: 45px;
      }
    }
  }
}