.btn-blue,
.btn-large-blue,
.btn-orange,
.btn-large-orange {
  @extend .btn;
  @include button-size(15px, 30px, 20px, 30px, 3px);

  @include button-variant(#fff, $ci-primary, $ci-primary);
  font-family: $font-family-serif;
  border: none;
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      outline: 0;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    outline: 0;
    background: #0055A5;
    text-decoration: none;
    color: $white;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(none);
  }

}

.btn-orange,
.btn-blue {
  @include button-size(10px, 15px, 15px, 30px, 0px);
}
.btn-blue,
.btn-large-blue {
  background: $ci-primary;
  color: $white;
}
.btn-orange,
.btn-large-orange {
  @include button-variant(#fff, $ci-secondary, $ci-secondary);
  background: $ci-secondary;
  color: $white;

  &:hover,
  &:focus,
  &.focus {
    color: $white;
    background: #E15F00;
    text-decoration: none;
  }
}

.btn-default {
  border-color: #d9d9d9;
  border-radius: 0;
}

.btn-light {
  @include button-variant($sparda-black, #d9d9d9, #d9d9d9, #d9d9d9, #d9d9d9);
  border-radius: 0;
}