.flat-table {
  width: 100%;
  font-family: $font-family-sans-serif;

  table {
    border: 0;
    width: 100%;
    display: block;
    overflow-x: auto;

    thead th {
      background: #fff;
      color: #000;
      text-align: center;
      padding: 4px 8px;
      font-weight: bold;
      font-family: $base-font-color;
    }

    tbody {
      tr:nth-of-type(odd) {
        background-color: $ci-table-background-color;
      }
      tr:nth-of-type(even) {
        background-color: #fff;
      }

      td {
        padding: 4px 8px;
        color: #000;
        text-align: center;
        a {
          color: $ci-link-color;
        }
      }

    }
  }
}

.flatmap-wrap {
  text-align: right;
  padding: 20px 0;
  h2 {
    display: inline-block;
    padding: 0 30px 0 0;
  }
  img {
    vertical-align: baseline;
  }
}


@include media-breakpoint-down(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {
  .flat-table {
    table {
      display: table;
    }
  }
}

@include media-breakpoint-up(xl) {
}

td,
th {
  padding: 0;
    vertical-align:top;
}