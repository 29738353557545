//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
  margin: 0;
  float: right;
}
.f3-widget-paginator {
  @include reset_list;
  display: inline-block;
  padding-left: 0;

  > li {
    display: inline; // Remove list-style and block-level defaults
    > a,
    > span {
      position: relative;
      float: left; // Collapse white-space
      padding: 4px 7px;
      text-decoration: none;
      color: #a5a5a5;
    }
    &:first-child {
      > a,
      > span {
        margin-left: 0;

      }
    }
    &:last-child {
      > a,
      > span {

      }
    }
  }

  > li > a {
    &:hover,
    &:focus {
      z-index: 2;
      color: $ci-secondary;
    }
  }

  > .current > span {
    color: $ci-secondary;
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
      color: $pagination-disabled-color;
      background-color: $pagination-disabled-bg;
      border-color: $pagination-disabled-border;
      cursor: $cursor-disabled;
    }
  }
  > .previous,
  > .next {
    width: 25px;

    a {
      display: block;
    }

    span {
      width: 0px;
      height: 0px;
      -webkit-transform:rotate(360deg);
      border-style: solid;
      border-width: 5px 0 5px 7px;
      border-color: transparent transparent transparent #a5a5a5;
      display: inline-block;
      position: absolute;
      top: 11px;
      right: 0px;
    }

    a:hover {
      span {
        border-color: transparent transparent transparent $ci-secondary;
      }
    }
  }
  > .previous {
    span {
      right: auto;
      left: 0;
      border-width: 5px 7px 5px 0;
      border-color: transparent #a5a5a5 transparent transparent;
    }

    a:hover {
      span {
        border-color: transparent $ci-secondary transparent transparent;
      }
    }
  }
}

p.small-text {
  font-size: 12px;
}