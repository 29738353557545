.tx-sparda-immobilien .immo-filtered {
  .immo-element {
    margin-bottom: 2rem;

    .immo-element-inner {
      border: 1px solid $brand-primary;
      height: 100%;

      img {
        aspect-ratio: 16/9;
        object-fit: cover;
      }

      > div {
        padding: 1rem;
      }
    }
  }
}