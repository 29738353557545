.video-stage {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: top;

  .video-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    box-sizing: content-box;
    overflow: hidden;

    .video-inner {
      width: 100%;
      height: 100%;

      &:hover {
        #videoControl {
          display: inline-block;
        }
      }

      #videoControl {
        width: 100px;
        height: 100px;
        background: rgba(0,0,0,0.6);
        margin: -50px 0 0 -50px;
        left: 50%;
        top: 50%;
        position: absolute;
        border-radius: 50%;
        text-indent: -5000px;
        display: none;
        z-index: 2;

        &:hover {
          display: inline-block;
        }

        &:after {
          position: absolute;
          margin: -25px 0 0 -10px;
          left: 50%;
          top: 50%;
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 25px 0 25px 30px;
          border-color: transparent transparent transparent rgba(255,255,255,0.8);
        }

        &:before {
          display: none;
        }

        &.playing {
          background: transparent;
          &:after {
            position: absolute;
            margin: -25px 0 0 -20px;
            left: 50%;
            top: 50%;
            content: "";
            width: 15px;
            height: 50px;
            background-color: transparent;
            border: none;
          }
          &:before {
            position: absolute;
            margin: -25px 0 0 5px;
            left: 50%;
            top: 50%;
            content: "";
            width: 15px;
            height: 50px;
            background-color: transparent;
            border: none;
            display: block;
          }
        }
      }

      video {
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
  }
}

.fullHeight,
#stage {
  height: 100%;
  width: 100%;
  position: relative;

  padding-top: 77px;
  .stage-container,
  .swiper-container,
  .teaser-image {
    height: 100%;
    width: 100%;
    background-position: center;
  }

  .stage-container {
    section {
      height: 100%;
      width: 100%;
      position: relative;
    }
  }

  .teaser-image {
    background-size: cover;
    background-position: center;
  }
  .stage-container {
    //@extend .container;
    position: relative;
  }

  .swiper-container {
    overflow: hidden;
  }
  .swiper-slide {
    background: $white;
  }

  .teaser-wrap {
    position: absolute;

    .teaser {
      border-left: 4px solid $ci-secondary;
      padding: 10px 10px;
      background: hsla(var(--ci-primary-h), var(--ci-primary-s), var(--ci-primary-l), 0.8);
      color: $white;

      .headline,
      h1 {
        @extend .font-teaser;
        padding-bottom: 20px;
      }

      .subline,
      h4, p {
        @extend .font-copy;
        padding-bottom: 10px;
      }

      .more-link {
        @extend .font-copy;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        a {
          color: $white;
        }
      }
    }

  }
}

@include media-breakpoint-down(md) {
  .fullHeight,
  #stage {
    .teaser-wrap {
      position: absolute;
      bottom: 20px;
      left: 0px;
      padding: 20px;
      width: 100%;

      .teaser {
        .headline,
        h1 {
          font-size: 18px;
          line-height: 30px;
          padding-bottom: 10px;

        }
        .subline,
        h4, p {
          font-size: 14px;
          padding-bottom: 0px;
        }

        .more-link {
          font-size: 14px;
        }
      }
    }
  }

}

@include media-breakpoint-up(lg) {
  .fullHeight,
  #stage {
    padding-top: 105px;

    .teaser-wrap {
      position: absolute;
      bottom: 10%;
      left: 10%;
      width: 50%;
      padding: 0;
      /*@include flexbox;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      */

      .teaser {
        padding: 20px 30px;
        background: hsla(var(--ci-primary-h), var(--ci-primary-s), var(--ci-primary-l), 0.8);
        color: $white;

        .subline,
        h4 {
          padding-bottom: 5px;
        }
      }
    }
    .swiper-button-prev {
      left: 50px;
      margin-top: -52px;
    }
    .swiper-button-next {
      right: 50px;
      margin-top: -52px;
    }
  }
}