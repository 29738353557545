.panel-accordion {
  padding-bottom: 20px;
  &.panel{
    margin:15px 0 15px 0;
  }
  .panel-heading {
    background: transparent;
    padding: 0 15px 0 0;

    h3 {
      line-height: 24px;
      padding: 0;

      a {
        display: block;
        color: $ci-primary;

        &:hover {
          text-decoration: none;
        }

        span {

          font-weight: normal;
          font-size: 14px;
          margin-top: -2px;

          &:before {
            @extend .fa-chevron-up;
            @extend .fa-solid;
          }
        }

        &.collapsed {
          span {
            &:before {
              @extend .fa-chevron-down;
            }
          }
        }
      }

    }
  }

  .panel-body {
    padding: 20px 5px;
    @include clearfix;
  }
}
